<script>
import { ANALYTICS_COLORS } from "@/package/const/analytics-colors";
import removeTrailingZeros from "@/package/helpers/format-number.helpers";
import MainChart from "@/components/helpers/MainChart.vue";

export default {
  name: "AnalyticsPieChart",

  components: { MainChart },

  props: {
    category: {
      type: Object,
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      updatingChart: false,

      options: {
        color: ANALYTICS_COLORS,
        tooltip: {
          trigger: "item",
          padding: [16],
          formatter: function (params) {
            return `<p style='font-size: 16px; color: #212121; margin-bottom: 16px;'>
              ${params.name}
              </p><div style='display: flex; align-items: center;'><span class='icon-analytics-pay' style='width: 20px; height: 20px; background-color: #666; margin-right: 4px;'></span><p style='color: #666;'>Баллов получено</p><p style='color: #212121; font-weight: 600; margin-left: 32px;'>
                ${removeTrailingZeros(params.value, 2)} RC
              </p></div>`;
          },
          textStyle: {
            fontFamily: "Montserrat",
            fontSize: 14,
          },
          position: "top",
        },
        series: [
          {
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 2,
            },
            name: "Выручка по кассирам",
            type: "pie",
            radius: ["55%", "90%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
            },
            labelLine: {
              show: false,
            },
            data: [],
            minAngle: 4,
          },
        ],
      },
    };
  },

  watch: {
    category: {
      deep: true,
      handler() {
        this.setChartOptions();
      },
    },
  },

  methods: {
    setChartOptions() {
      let name = "";
      let data = [];

      name = this.category.name;
      this.data[this.category.id].series.forEach((item) => {
        const sum = item.data.reduce((sum, el) => {
          return sum + el;
        }, 0);

        if (sum > 0) {
          data.push({
            name: item.name,
            value: sum,
          });
        }
      });

      this.updatingChart = true;

      this.options.series[0].name = name;
      this.options.series[0].data = data;

      setTimeout(() => {
        this.updatingChart = false;
      }, 100);
    },
  },

  beforeMount() {
    this.setChartOptions();
  },
};
</script>

<template>
  <div class="pie-chart">
    <transition name="fade">
      <MainChart
        v-if="!updatingChart"
        class="pie-chart__chart"
        :options="options"
      />
    </transition>
  </div>
</template>

<style scoped lang="scss">
.pie-chart {
  height: 220px;
  width: 100%;
}
</style>
