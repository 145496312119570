export const ANALYTICS_CARDS = [
  {
    id: "total",
    icon: "pay",
    name: "Суммарный доход",
  },
  {
    id: "marketing",
    icon: "money",
    name: "План возна-<br />граждений",
    color: "#FF6666",
  },
  {
    id: "gift",
    icon: "gift",
    name: "Подарочный счет",
    color: "#5BAF54",
  },
  {
    id: "rc_market_income",
    icon: "shop",
    name: "Доход<br /> по RC MARKET",
    color: "#FFDA7A",
  },
  {
    id: "rc_city_income",
    icon: "tsp",
    name: "Доход<br /> по RC CITY",
    color: "#2F80ED",
  },
  {
    id: "samo",
    icon: "samo",
    name: "Доход<br /> по САМО",
    color: "#FF49CC",
  },
  {
    id: "online_selling",
    icon: "cashback",
    name: "Онлайн-покупки",
    color: "#FFBCD0",
  },
];
